import createApp from '@shopify/app-bridge';
import {AppProvider, Page, Card, Button} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';


const config = {
    // The client ID provided for your application in the Partner Dashboard.
    apiKey: "9c7d7cd92d133841909606fcca3f810c",
    // The host of the specific shop that's embedding your app. This value is provided by Shopify as a URL query parameter that's appended to your application URL when your app is loaded inside the Shopify admin.
    host: new URLSearchParams(window.location.search).get("host"),
    forceRedirect: true
};
const app = createApp(config);



function App() {
  return (
    <AppProvider i18n={[enTranslations]}>
      <Page>
        <Card>
          <Button>Test</Button>
        </Card>
      </Page>
    </AppProvider>

  );
}

export default App;